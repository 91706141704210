.MainScreen {
    display: flex;
    background-color: #f5f5f5;
    width: 100%;
    height: 100%;
}

.MainScreen__sideBar {
    width: 4%;
    height: 100%;
    background-color: #f5f5f5;
}

.MainScreen__home {
    width: 96%;
    background-color: #f5f5f5;
    height: 100%;
}

@media(max-width:480px) {
    .MainScreen__home {
        margin-right: 7px;
    }
}
@media (max-width:700px)  {
    .MainScreen__sideBar{
        width:0%;
    }
    .MainScreen__home{
        width:100%;
    }
}