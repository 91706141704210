.sidebar {
    background-color: #434c56;
    min-height: 100%;
    position: fixed;
    top: 0;
    z-index: 200;
}

.sidebar__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    gap: 27px;
    padding: 31.2px 10px;
}

.iconBox {
    display: flex;
    flex-direction: column;
}
.text{
    color:white;
    font-size:10px;
}
.link{
    text-decoration: none;
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media (max-width:480px) {
    .sidebar {
        display: none;
    }

}

.sidebar1 {
    background-color: #434c56;
    min-height: 100%;
    position: fixed;
    top: 0;
    z-index: 200;
}