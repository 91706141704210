.weekly__container {
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  min-width: 100vw;
  background-color: #f6f6f6;
}

.weekly__sideBar {
  height: 100%;
  width: 4%
}

.weeklyExpense__card {
  height: 100vh;
  width: 96%;
  display: grid;
  place-items: center;
}

.searchDate {
  position: absolute;
  top: 90px;
  left: 90px;
  height: 45px;
  width: 25%;
  border-radius: 5px;
  display: flex;
  align-items: center;
  box-shadow: 0 1px 12px -9px rgba(0, 0, 0, 0.5);
  background-color: white;

}

.searchDateButton>Button {
  background-color: #ff7377;
  color: white
}

.searchDateButton>Button:hover {
  background-color: #ff7370;
  color: white
}

.expenseCard__searchBar {
  padding-left: 10px;
  height: 100%;
  display: flex;
  align-items: center;
}

@media(max-width:750px) and (min-width:480px){
  .weekly__sideBar{
    display: none;
  }
  .weeklyExpense__card{
    margin-left: 0px;
  }
}
@media(max-width:1000px) and (min-width:750px){
  .weeklyExpense__card{
    margin-left: 3.3%;
  }
}
