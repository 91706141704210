.card {
    border-radius: 10px;
    overflow: hidden;
    margin: 10px;
    min-width: 25%;
    min-height: 220px;
    background-color: white;
    cursor: pointer;
    box-shadow: 0 6px 18px -9px rgba(0, 0, 0, 0.5);
    transition: transform 150ms cubic-bezier(0.25, 0.46, 0.46, 0.46);
}
.card a{
    text-decoration: none;
}
.card:hover {
    transform: scale(1.07);
}

.card__title {
    padding: 10px;
    background-color: #ff7378;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
    height: 20%;
}

.card__title>h5 {
    font-size: 16px;
    font-weight: 400;
}

.card__detail {
    font-size: 14px;
    font-weight: 300;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    height: 66%;
    flex-direction: column;
}

.card__detail>h3 {
    font-weight: 310;
    font-size: 40px;
}

@media (max-width:480px) {
    .card__detail {
        height: 175px;
    }

    .card {
        max-height: 12vh;
    }
}

@media (min-width:1500px) {
    .card {
        min-width: 25vw;
        min-height: 280px;
    }
}

@media(max-width:1000px) and (min-width:480px) {
    .card {
       min-width: 300px;
       max-height: 250px;
    }
    .card__detail {
        height: 175px;
    }
}
