.order {
    min-height: 100vh;
    min-width: 100vw;
    background-color: #f6f6f6;
    display: flex;
    flex-direction: row;
}

.order__sideBar {
    height: 100%;
    width: 4%;
}

.order__container {
    margin-left: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}
@media(max-width:600px) and (min-width:480px){
    .order__sideBar {
      opacity: 0;
      display: none;
    }
    .order__container{
        margin-left: 0px;
    }
}

@media (max-width:480px){
    .order__container{
        margin-left: 0px;
    }
}