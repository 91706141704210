.orderTracking {
    width: 100vw;
    background-color: #f6f6f6;
    height: 100vh;
    display: flex;
    /* overflow: hidden; */
}

.sidebar {
    width: 4%;
}

.trackingCard {
    width: 96%;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media(max-width:1100px) and (min-width:480px) {
    .sidebar {
        display: none;

    }
    .orderTracking{
        margin-left: 2.3%;
       
    }
}

@media(max-width:480px) {
    .sidebar {
        display: none;

    }
    .orderTracking {
        overflow-x: scroll;
        margin-right: 0px;
         
    }

    .trackingCard {
        min-width: 600px;
        overflow: scroll;
    }

}