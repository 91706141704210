.customer {
    display: flex;
}

.sideBar {
    width: 5%;
}

.customerCard {
    width: 95%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

}
@media(max-width:500px){
    .customer{
        min-width:800px;
    }
}