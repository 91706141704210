.assign {
    height: 440px;
    /* height: 70%; */
    width: 70%;
    background-color: white;
    border-radius: 10px;
    z-index: 200;
    box-shadow: 0 6px 18px -3px rgba(0, 0, 0, 0.5);
}

.header {
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 10px;
    border-bottom: 1px solid lightgray;

}

.header h5 {
    font-size: 18px;
    font-weight: 600;
    margin: 0px 15px;
}

.riderContainer {
    height: 270px;
    overflow-y: auto;
}

.riderDetail {
    display: flex;
    padding: 20px;
    justify-content: space-between;
    border-bottom: 1px solid lightgray;

}

.riderItem {
    display: flex;
    justify-content: space-between;
    width: 50%;
}

.riderItem>h5 {
    font-size: 18px;
    font-weight: 600;
    padding: 0px 5px;
}

.bottomContainer {
    height: 90px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.bottomContainer>button {
    background-color: #ff7378;
    padding: 10px 25px;
    border: none;
    outline-width: none;
    width: 30%;
    color: white;
    border-radius: 25px;
    margin: 10px 0px;
    cursor: pointer;
}

.bottomContainer>button:disabled {
    background-color: rgba(255, 115, 120, 0.73);
    ;
}

.bottomContainer>h5 {
    font-size: 18px;
    font-weight: 600;
    margin-top: 10px;
}

@media(max-width:480px) {
    .assign {
        width: 100%
    }

    .riderItem {
        width: 50%;
    }
}

.loading {
    position: absolute;
    top: 48%;
    left: 38vw;
    z-index: 20;
}

.noRider {
    position: absolute;
    top: 48%;
    left: 35vw;
    z-index: 20;
}

.noRider>h5 {
    font-size: 20px;
    font-weight: 600;
}