.cart {
    height: 580px;
    width: 50%;
    background-color: white;
    z-index: 300;
    border-radius: 5px;
    box-shadow: 0 6px 18px -9px rgba(0, 0, 0, 0.5);
    ;
    overflow: hidden;
}

.header {
    padding: 10px 15px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid lightgrey;
}

.header>h5 {
    margin-top: 4px;
    font-size: 20px;
    font-weight: 600;
}

.titleContainer {
    height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
 
}

.titleContainer>h5 {
    font-size: 20px;
    font-weight: 600;
}

.titleContainer>h6 {
    font-size: 18px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.5);
    margin-top: 10px;
}

.totalPriceContainer {
    /* height: 60px; */
    border-top: 1px solid lightgray;
    display: grid;
    grid-template-columns: repeat(2, minmax(0,
                0.5fr));
    padding:6px 15px;
    

}

.totalPriceContainer>h5 {
    font-size: 17px;
    font-weight: 600;
    margin: 1px 0px 0px 0px;
}

.totalPriceContainer>span {
    font-size: 17px;
}

.updateButton {
    height: 100px;
    width: 100%;
    padding: 15px 20px;

}

.updateButton>button {
    padding: 14px 15px;
    border-radius: 7px;
    border: none;
    background-color: #ff7378;
    color: white;
    width: 90%;
    cursor: pointer;
    margin-left: 10px;
}

.updateButton>button:disabled {
    background-color: "#cacaca"
}

.product {
    display: flex;
    justify-content: space-between;
    padding: 15px 20px;
    margin: 10px 0px;
}

.productPrice {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.product>h5 {
    font-size: 18px;
    font-weight: 500;
}

.productPrice>h6 {
    font-size: 17px;
    font-weight: 600;
}

.counter {
    display: flex;
    align-items: center;
    border-radius: 6px;
    margin-top: 5px;
    padding: 2px;
    cursor: pointer;
    transition: transform 150ms cubic-bezier(0.25, 0.46, 0.46, 0.46);
    border: 0.5px solid lightgray
}

.counter:hover {
    transform: scale(1.07);
    box-shadow: 0 3px 15px -9px rgba(0, 0, 0, 0.5);
    ;

}

.icon {
    margin: 0px 15px;
}
.productContainer{
    height: 300px;
    overflow-y: auto;
}
.loading{
    position: absolute;
    left:50%;
    bottom: 45%;

}