.customersCard {
    height: 560px;
    width: 90vw;
    background-color: #ffff;
    border-radius: 8px;
    box-shadow: 0 6px 18px -9px rgba(0, 0, 0, 0.5);
}

.header {
    height: 30px;
    padding: 22px
}

.header>h5 {
    font-size: 21px;
    font-weight: 600;
}

.titleBar {
    height: 50px;
    border-top: 1px solid lightgray;
    border-bottom: 1px solid lightgrey;
    background-color: #f1f1f1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 20px;
}

.titleBar>h2 {
    font-size: 18px;
    font-weight: 500;
}

.dataItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 22px;
    border-bottom: 1px solid lightgrey;
    cursor: pointer;
    padding-left: 40px;
}

.dataItem>span {
    width: 100px;
    font-size: 16px;
    font-weight: 400;
}

.dataItem:hover {
    background-color: #f5f5f5;
}

.dataContainer {
    height: 330px;
    overflow-y: auto;
}

.search {
    padding: 0px 0px 20px 0px;
    display: flex;
    justify-content: center;
}

.input {
    border: 1px solid lightgrey;
    width: 50%;
    border-radius: 10px;
    padding: 5px;
    display: flex;
    box-shadow: 0 2px 10px -9px rgba(0, 0, 0, 0.5);
    justify-content: space-between;
}

.input:hover {
    border: 1px solid dodgerblue
}

.input>input {
    border: none;
    outline: none;
    font-size: 16px;
    padding: 0px 20px;
    margin-top: 3px;
    width: 100%;

}

.searchIcon {
    font-size: 22px;
    text-align: center;
}

.searchButton {
    background-color: black;
}

.searchTitle {
    position: absolute;
    top: 60%;
    left: 45%;
}

.searchTitle>span {
    font-size: 22px;
    font-weight: 400;
}

.searchButton {
    display: none;
}

.editModal {
    position: absolute;
    height: 100vh;
    width: 90vw;
     top:0px; 
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 200;
}
.loading {
    position: absolute;
     top:55%; 
     left:50%
  
}
@media (max-width:550px) {
    .customersCard{
        min-width:800px;
      
    }
    .editModal{
        min-width: 800px
    }
    .loading {
        position: absolute;
         top:55%; 
         left:400px
      
    }
    .searchTitle {
        position: absolute;
        top: 60%;
        left: 400px;
    }
}