.graph__box {
    width: 100%;
    height: 280px;
    background-color: white;
    border-radius: 10px;
    cursor: pointer;
    box-shadow: 0px 8px 16px -9px rgba(0, 0, 0, 0.5);
}

.responsive__box {
    position: relative;
    right: -10px;
}

.graph__title {
    font-size: 20px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 20px;
}

@media(max-width:480px) {
    .responsive__box {
        position: relative;
        right: 0;
    }

    .graph__lineChart {
        position: relative;
        right: 7px;
    }
}
@media(max-width:1000px) and (min-width:480px){
    .graph__box{
        min-width: 300px;
    }
   
}