.addToCart {
    width: 40vw;
    min-height: 500px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 6px 18px -9px rgba(0, 0, 0, 0.5);
    z-index: 200;
    padding: 0px 10px;
}

.header {
    display: flex;
    justify-content: flex-end;
    padding: 8px;
}

.subHeader {

    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
    border-bottom: 1px solid lightgray;
}

.title>h5 {
    font-size: 21px;
    font-weight: 600;
}

.title>h6 {
    font-size: 13px;
    color: rgba(0, 0, 0, 0.5);
    font-weight: 600;
}
.price{
    display: flex;
}
.price>h5 {
    text-align: center;
    font-size: 13px;
    color: rgba(0, 0, 0, 0.5);
    font-weight: 600;
    margin-top: 13px;
    margin-left:10px
   
}

.contentContainer {
    height: 176px;
    display: flex;
    flex-direction: column;
    position: relative;
}

.content {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 0px 20px;
    width: 15%;

}

.number {
    margin: 10px 0px;
    font-size: 18px;
    font-weight: 600;
}

.addToCartButton {
    padding: 15px;
    width: 85%;
    background-color: #ff7378;
    border-radius: 8px;
    outline: none;
    border: none;
    color: white;
    cursor: pointer;
}
.addToCartButton:disabled{
    background-color:#cacaca ;
}
.addToCartIconButtonContainer {
    background-color: white;
    border-top: 1px solid lightgray;
    width: 100%;
    display: flex;
    padding: 10px 0px;
    height: 50px;
    margin-top: auto;
    position: absolute;
    bottom:-200px
}
.flavour{
    width:100%;
}