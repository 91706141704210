.home {
    position: relative;
    min-height: calc(100vh - 250px);
    overflow-x: hidden;
    display: block;
    top: 100px;
    background-color: #f5f5f5;
}

.home__loading {
    display: flex;
    min-height: 100vh;
    background-color: #f5f5f5;
    align-items: center;
    justify-content: center;
    flex-direction: column;

}

.home__orderbox {
    display: flex;
    justify-content: center;
    z-index: 1;
}

.container {
    display: flex;
    justify-content: center;
}


.box__avg {
    width: calc(26% + 10px);
    margin-right: 10px;
    z-index: 1;
}

.graph__container {
    width: 51.8%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;

}

.home__box_avg {
    display: flex;
    justify-content: center;
}

@media (max-width:480px) {
    .home__orderbox {
        display: flex;
        flex-direction: column;

    }

    /* 
    .home {
        min-width: 90vw;
        width: 90vw;
    } */

    .container {
        display: flex;
        flex-direction: column;
        margin-left: 12px;
    }

    .graph__container {
        width: 93vw;

    }

    .box__avg {
        width: 93vw;
    }


}

@media (min-width:1500px) {
    .box__avg {
        margin-right: 3vw;
        /* width: 26vw; */
    }

    .graph__container {
        position: relative;
        left: -30px;
    }
}

@media (min-width:1500px) and (max-width:1800px) {
    .graph__container {
        position: relative;
        left: -15px;
    }
}


@media(min-width:480px) and (max-width:1000px) {
    .home__orderbox {
        display: flex;
        flex-direction: column;
        margin-left: 80px;
        width: 80%;
        justify-content: center;

    }

    .container {
        display: flex;
        flex-direction: column;
        align-items: center;

    }

    .box__avg {
        width: 80%;

    }

    .graph__container {
        width: 80%;
    }
}

@media(max-width:770px) and (min-width:480px) {
    .container {
        padding-left: 20px;

    }

    .box__avg {
        margin-right: 0px;
    }
}