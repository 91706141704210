.expenseCard__container {
    height: 330px;
    width: 90vw;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 6px 18px -9px rgba(0, 0, 0, 0.5);
    overflow: hidden;

}

.expenseHeader__title {
    font-size: 22px;
    font-weight: 600;
}

.expenseCard__header {
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    border-bottom: 1px solid lightgray;
    padding: 17px;
}

.expenseCard__search {
    height: 45px;
    width: 25%;
    border: 1px solid lightgray;
    border-radius: 5px;
    display: flex;
    align-items: center;
    box-shadow: 0 1px 12px -9px rgba(0, 0, 0, 0.5);
}

.expense__filter {
    width: 25%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.expenseCard__searchBar {
    border-left: 1px solid lightgray;
    padding-left: 10px;
    height: 100%;
    display: flex;
    align-items: center;
}

.expenseCard__data {
    height: 65%;
    width: 100%;
}

.expenseCard__dataTitlesBar {
    border-bottom: 1px solid lightgray;
    padding: 20px;
    display: flex;
    background-color: #f5f5f5;
    display: grid;
    grid-gap: 60px;

    grid-template-columns: repeat(6, minmax(0, 1fr));
    color: #000;
    font-weight: 500;
}

.expenseCard__dataTitlesBar>h5 {
    font-size: 17px;
    font-weight: 500;
}

.expenseCard__dataItem {
    display: flex;
    align-items: center;
    padding: 27px 10px 27px 22px;
    border-bottom: 1px solid lightgray;
    display: grid;
    grid-gap: 60px;
    grid-template-columns: repeat(6, minmax(0, 1fr));
    font-size: 16px;
    color: #5b5b5b;
    cursor: pointer;


}

.expenseCard__dataItem:hover {
    background-color: #f6f6f6;
}

.expenseCard__dataItem>h6 {
    font-size: medium;
    font-weight: 400;
}

.expenseCard__nav {
    height: 10%;
    width: 13%;
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
}

.expenseCar__iconbox {
    height: 35px;
    width: 35px;
    border: 1px solid lightgray;
    display: grid;
    place-items: center;
    border-radius: 7px;
    cursor: pointer;
}

.expense__filter>Button {
    background-color: #ff7378;
    color: white;
    height: 100%;
}

.expense__filter>Button:hover {
    background-color: #ff7370;
    color: white;
}

.dateRange {
    position: absolute;
    top: 35vh;
    width: 600px;
    background-color: white;
    z-index: 2;
}

.dateRange>Button {
    background-color: #ff7378;
    color: white;
    width: 100%
}

.dateRange>Button:hover {
    background-color: white;
    color: #ff7378;
}

.locationDropDown {
    border: 1px solid lightgray;
    width: 160px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 1px 12px -9px rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 0;
    cursor: pointer;

}

.dropdown {
    padding-left: 50px;
    outline: 0;
    border-width: 0;
}

.dropdown:hover {
    padding-left: 50px;
    outline-width: none;
    border-width: none;
}

.searchBox {
    padding: 5px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 1px 12px -9px rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    cursor: pointer;
    width: 30vw;
    border: 1px solid lightgray;
    margin-left: 20px;
}

.searchBox:hover {
    border: 1px solid black;
}

.searchBox>Button:disabled {
    background-color: rgba(255, 115, 120, 0.65);
    color: #ffff;
}

.searchBox>Button {
    background-color: #ff7378;
    color: white;
}
.rdrDay:hover{
    background-color: #000;
}
.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input{
    height:0px
}
.searchBox>Button:hover {
    background-color: white;
    color: #ff7378;
    border: 1px solid #ff7378;
}

.dateBox {
    display: flex;
    justify-content: center;
    width: 250px;

}

.dateBox>h5 {
    font-size: 15px;
    font-weight: 600;

}

.locationButton>h5 {
    font-size: 16px;
    font-weight: 600;
}

.locationButton>h6 {
    font-size: 16px;
    font-weight: 600;
}

.noRecord {
    width: 49vw;
    display: flex;
    justify-content: flex-end;
}

.noRecord h5 {
    font-size: 18px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.5);
}
@media (min-width:480px) and (max-width:700px) {
    .searchBox {
        width: 80%
    }

    .expenseCard__container {
        min-width: 700px;
        box-shadow: 0 6px 18px 1px rgba(0, 0, 0, 0.5);
        margin-top: 20%;
        overflow: scroll;
    }
}

@media(max-width:480px) {
    .searchBox {
        width: 80%
    }

    .expenseCard__container {
        min-width: 700px;
        box-shadow: 0 6px 18px 1px rgba(0, 0, 0, 0.5);
        margin-top: 50%;
        overflow: scroll;
    }

}

@media(min-width:700px) and (max-width:1000px) {
    .searchBox {
        width: 80%
    }
}