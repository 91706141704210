.editProfile {
    height: 550px;
    width: 50%;
    background-color: white;
    box-shadow: 0 6px 18px -9px rgba(0, 0, 0, 0.5);
    z-index: 200;
    border-radius: 10px;
}

.header {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    height: 40px;
    border-bottom: 1px solid lightgray
}

.header h5 {
    font-size: 18px;
    font-weight: 600;
    margin-top: 5px;
    text-align: center;
}

.editContainer {
    height: 310px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 90%;
    margin: auto;

}

.updateBtn {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
@media(max-width:500px){
    .editProfile{
        min-width: 400px
    }
}