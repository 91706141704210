.orders__card {
    min-height: 500px;
    width: 90vw;
    background-color: #ffff;
    border-radius: 8px;
    box-shadow: 0 6px 18px -9px rgba(0, 0, 0, 0.5);
    padding: 20px;
}

.orders__divideBar {
    height: 2.5px;
    background-color: #f6f6f6;
}

.orders__cardHeading {
    padding: 15px;
    font-size: 20px;
    font-weight: 600;
    display: flex;
}

.orders__cardHeading>h3 {
    font-size: 20;
    font-weight: 480;
}

.orders__cardTitleBar {
    padding: 20px;
    background-color: #f1f1f1;
    border-radius: 10px;
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    gap: 50px;

}

.orders__cardTitleBar>h5 {
    font-size: 20px;
    font-weight: 400;
}

.cardTitleBar__text {
    font-size: 20px;
    color: #000;
}

.orders__cardData {
    height: 380px;
    overflow-y: auto;
}

.orders__cardDataItem {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 10px 20px;
    border-bottom: 1px solid #f1f1f1;
    cursor: pointer;
    gap: 20px;

}

.orders__cardDataItem>h6 {
    font-size: 18;
    font-weight: 400;
}

.orders__cardDataItem:hover {
    background-color: #f5f5f5;

}

.orders__cardDataItemText {
    width: 20%;
    font-size: 18px;
    color: #000;
    text-align: center;
    width: 15%;
}

.order__cardButton {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 30px;
}

.orders__viewDetails {
    display: flex;
    align-items: center;
    color: red;
    position: relative;
    top: 5px;
    left: 25px;
    cursor: pointer;
    font-size: 15px;

}

.order__iconBox {
    height: 35px;
    border: 1px solid lightgray;
    background-color: #f6f6f6;
    width: 35px;
    border-radius: 8px;
    display: grid;
    place-items: center;
    cursor: pointer;

}

.order__nextOrder {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.orders_nextOrderStatus {
    border: 1px solid lightgray;
    background-color: white;
    border-radius: 8px;
    height: 35px;
    width: 35px;
    display: grid;
    place-items: center;
}

.item-img {
    width: 140px;
    height: 140px;
    margin: auto;
    margin-bottom: 1rem;
}

@media(max-width:700px) {
    .orders__card {
        width: 90%;
        box-shadow: 0 6px 18px 1px rgba(0, 0, 0, 0.5);
        margin-top: 10%;
    }

    .orders__cardData {
        overflow-y: auto;
    }

    .orders__cardDataItemText {
        font-size: 16px;
    }

    #orderid {
        display: none;
    }

    #orderid1 {
        display: none;
    }

}

#row {
    height: 75%;
    width: 55px;
    margin-top: 2px;
}

.rowSelect {
    display: flex;
    align-items: center;
}

@media(min-width:700px) and (max-width:1200px) {
    #orderid {
        display: none;
    }

    #orderid1 {
        display: none;
    }
}