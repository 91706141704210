.flavour {
    /* height:100%; */
    padding: 0px 17px;
}

.header {
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
}

.required {
    padding: 5px 20px;
    background-color: #f6f6f6;
    border-radius: 5px;
}

.required>span {
    font-size: 13px;
}

.header>h5 {
    font-size: 17px;
    font-weight: 500;
}

.itemContainer {
    /* min-height: 100px; */
    overflow-y: auto;
    max-height: 270px;
}

.item>h6 {
    margin: 0px 8px;
    font-size: 15px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.5);
}

.itemContainer>input {
    margin: 4px 0px;
}

.item {
    display: flex;
}
.flavourContainer{
    height:300px;
    overflow-y: auto;
}