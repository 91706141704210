.restaurantModal {
    width: 1100px;
    height: 550px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 6px 18px -9px rgba(0, 0, 0, 0.5);
    z-index: 200;
}

.headerContainer {
    padding: 20px 25px;
    display: flex;
    justify-content: space-between;
}

.close {
    cursor: pointer;
}

.headerContainer>h5 {
    font-size: 20px;
    font-weight: 600;
}

.cart {
    font-size: 24px;
    cursor: pointer;
}

.searchContainer {
    padding: 20px 0px;
    display: flex;
    justify-content: center;
}

.search {
    padding: 10px 15px;
    width: 50%;
    border-radius: 5px;
    outline-width: none;
    border: 1px solid lightgray;
    display: flex;
    box-shadow: 0 2px 10px -9px rgba(0, 0, 0, 0.5);
}

.inputBox {
    border: none;
    outline: none;
    width: 100%;
    padding: 0px 20px;
    font-size: 18px;
}

.searchIcon {
    font-size: 20px;
}

.categoryList {
    padding: 14px;
    box-shadow: 0 4px 12px -9px rgba(0, 0, 0, 0.5);
    display: flex;
    border: 1px solid #f3f3f3;

    overflow-x: auto;
    /* background-color: red; */
}

.categoryList>button>h5 {
    padding: 5px 10px;
    font-size: 13px;
    font-weight: 600;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.7);
}

.categoryContainer {
    height: 250px;
    overflow-y: auto;
    width: 95%;
    margin: auto;
    /* background-color: red; */
}
/* .categoryList::-webkit-scrollbar{
    display: none;
} */

.title {
    display: flex;
    align-items: center;
    padding: 12px 0px 0px 0px;
}

.title>h5 {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    margin-left: 3px;
}

.shotIcon {
    font-size: 20px;
}

.subTitle {
    font-size: 13px;
    text-align: center;
}

.product {
    height: 120px;
    background-color: white;
    box-shadow: 0 2px 5px -1px rgba(0, 0, 0, 0.5);
    border-radius: 7px;
    margin: 25px 20px;
    position: relative;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    cursor: pointer;
}

.productItemContainer {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
}

.contentContainer {
    padding: 15px 20px;
}

.contentContainer h5 {
    font-size: 15px;
    font-weight: 600;
    margin: 3px 0px;
}

.plusIcon {
    position: absolute;
    bottom: 30px;
    left: 90%;

}

.modalContainer {
    height: 100%;
    width: 80vw;
    position: absolute;
    top: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.cartIcon{
    margin: 3px 5px;
    color: black;
}

.categoryList>button{
    background-color: white;
    outline: none;
    border: none;
}
.categoryList>button:hover{
    border-bottom: 2px solid #ff7378;
}
