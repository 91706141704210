.removeRider {
    width: 40%;
    background-color: white;
    box-shadow: 0 6px 18px -3px rgba(0, 0, 0, 0.5);
    z-index: 200;
    border-radius: 10px;
}

.header {
    height: 40px;
    display: flex;
    justify-content: flex-end;
}

.removeContentContainer {
    height: 205px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.removeContentContainer>h5 {
    font-size: 20px;
    font-weight: 600;
    margin: 5px 0px;
}

.removeContentContainer>button {
    width: 40%;
    padding: 12px 20px;
    border-radius: 25px;
    outline-width: none;
    border: none;
    background-color: #ff7378;
    color: white;
    margin: 10px 0px;
    cursor: pointer;
}

@media (max-width:480px) {
    .removeRider {
        width: 100%;
    }
    .removeContentContainer>h5{
        text-align: center;
    }
}