.box {
    min-height: 130px;
    background-color: white;
    margin: 10px;
    box-shadow: 0 6px 18px -9px rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    overflow: hidden;
    min-width: 25%;
}

.title {
    height: 100%;
    width: 43%;
    margin-left: 30px;
}

.title>h5 {
    font-size: 20px;
    font-weight: 330;
}

.number>h3 {
    font-size: 30px;
    font-weight: 350;
}

.MoreHorizIcon {
    margin-bottom: 75px;
    margin-right: 5px;
}

@media(min-width:480px) and (max-width:1000px) {
    .box {
        width: 100%;
    }
}

