.header {
    width: 100%;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    top: 0;
    z-index: 100;
    background-color: white;
    left: 37px
}

.header__icon {
    object-fit: contain;
    height: 95px;
    margin-left: 45px;

}

.header__search {
    display: flex;
    align-items: center;
    max-width: fit-content;

    border-radius: 100px;
    height: 30px;
    padding: 7px;
    padding-left: 10px;
    background-color: #f5f5f5;
}

.header__search>input {
    outline-width: 0;
    border: none;
    margin-left: 10px;
    background-color: #f5f5f5;
    transition: width 5s cubic-bezier(0.25, 0.46, 0.46, 0.46);
}

.header__search>input:focus {
    width: 250px;
}




.header__right {
    width: 32vw;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: 50px;
    background-color: white;

}

.ExpandMore {
    position: relative;
    right: 1.5vw;
}

.avatar {
    display: flex;
    justify-content: center;
    align-items: center;
}

.avatar_image {
    object-fit: contain;
    max-width: 45px
}

.adminName {
    position: absolute;
    top: 67px;
    font-size: 13px;
    right: 58px;
    cursor: pointer;
    text-align: center;
}

@media (max-width:480px) {
    .header__right {
        margin-right: 10px;
    }

    .header__right input {
        display: none;
    }

    .header {
        left: 0;
        width: 100vw;
        display: flex;
        justify-content: center;

    }

    .header__icon {
        margin-left: 0px;

    }

    .header__right {
        display: none;
    }

}