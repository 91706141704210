@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

.selectCity {
    height: 100vh;
    width: 100%;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.selectCity_modal {
    height: 500px;
    min-width: 1000px;
    box-shadow: 0 6px 18px -9px rgba(0, 0, 0, 0.5);
    background-color: white;
    border-radius: 10px;
    display: flex;
    overflow: hidden;

}

.selectCity_leftBox {
    border-right: 1px solid lightgrey;
    width: 30%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}

.selectCity_leftBox>h5 {
    font-size: 25px;
    font-weight: 600;
    line-height: 20px;
    font-family: 'Roboto', sans-serif;
}

.selectCity_leftBox>button {
    background-color: #000;
    color: #fff;
    border-radius: 5px;
    margin-top: 30px;
    padding: 8px 40px;
}

.selectCity_leftBox>button:disabled {
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    cursor: pointer;
}

.citiesList {
    display: flex;
    flex-direction: column;


}

.button_box {
    height: 50%;
    display: grid;
    place-items: center;
    width: 700px;

}

.button_box>button {
    height: 100%;
    width: 100%;
    /* background-color: #f6f6f6; */
}

.button_box span {
    font-size: 22px;
    font-weight: 600;
    color: #000;
    font-family: 'Roboto', sans-serif;
    text-transform: capitalize;
}

@media(max-width:1000px) {
    .selectCity {
        min-width: 1000px;
        overflow: scroll;
    }
    .selectCity_modal {
        width: 100%;
        overflow: scroll;
    }
}